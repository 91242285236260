:root {
    --doc-height: 100%;
}

html,
body {
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;
    height: var(--doc-height);
}

div#root, div.layoutRoot, div.leaflet-container {
    width: 100%;
    height: 100%;
}

/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.sykkelkart_legend {
    background-color: #f2efe9;
    padding: 1em;
    border-color: lightgrey;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
}

div.legend_entry {
    display: block;
}

    div.legend_entry span {
        display: inline-block;
        vertical-align: bottom;
    }


.sykkelkart_logo {
    width: 30px;
    height: 30px;
}
